import * as React from "react";
import LandingPage from "./LandingPage";
import Home from "./Home";
import { useLocation } from "@reach/router";
import { GlobalStyle } from "../layout/global";

const IndexPage = () => {
  const location = useLocation();
  const path = location.pathname;
  
  return (
    <>
      <link href="https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&family=Raleway:wght@400;700&display=swap" rel="stylesheet" />
      {path === "/" ? (
        <>
          <GlobalStyle />
          <LandingPage />
        </>
      ) : (
        <>
          <GlobalStyle />
          <Home />
        </>
      )}
    </>
  );
};

export default IndexPage;
