import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&family=Raleway:wght@400;700&display=swap');

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth !important;
  }

  html, body{
    object-fit: cover;
  }

  .accordion{
   width: 500px;

    @media (max-width: 500px) {
      width: 398px;
    }
  }

`;
